import React from 'react';
import { IndexBody } from '@containers';
import { graphql } from 'gatsby';
import { Layout } from '@components';
import { SEO } from '../components/seo';

const Index = ({ data }) => {
  if (!data?.allMarkdownRemark?.edges?.[0]) {
    return '';
  }
  return (
    <Layout>
      <IndexBody pageData={data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter} />
    </Layout>
  );
};
export default Index;

export const Head = ({ data }) => {
  return <SEO {...data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.seo} />;
};

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(filter: { frontmatter: { containerPath: { eq: "indexBody/IndexBody" } } }) {
      edges {
        node {
          frontmatter {
            containerPath
            homeHeroContainer {
              heroBgIcon
              title
              infoBox {
                infoTextOne
                infoTextTwo
                infoTitle
              }
            }
            homeTextArea {
              heading1
              text1
            }
            homeAccordionContainer {
              text
              accordionItems {
                accordionHeading
                accordionText
              }
            }
            homeSlider {
              slides {
                slideHeading
                slideText
                slideImage
              }
            }
            homeSubscribeForm {
              formHeading
              formText
            }
            seo {
              title
              favicon
              meta {
                name
                content
              }
            }
          }
        }
      }
    }
  }
`;
